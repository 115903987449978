import React, { useRef, useEffect } from 'react'
import gsap from 'gsap'
import ShowFlow from './ShowFlow'

export default function SectionBar ({ show, section, onPlayVideo }) {
	const bar = useRef()
	const counter = useRef(1)
	useEffect(() => {
		counter.current = 1
		bar.current.querySelectorAll('.home-section-bar-cell').forEach(vid => {
			if (counter.current === section) {
				gsap.to(bar.current, { scrollLeft: vid.offsetLeft, duration: 1 })
			}
			counter.current += 1
		})
	}, [section])

	return (
		<div className={show ? 'home-section-bar home-section-bar-visible' : 'home-section-bar'}>
			<div ref={bar} className="home-section-bar-inner">
				{ShowFlow.map((show, s) => {
					const ss = s + 1
					return <button key={'show' + s} onClick={() => onPlayVideo(ss)} className={section === ss ? 'home-section-bar-cell home-section-bar-cell-active' : 'home-section-bar-cell'}><span>{show.subtitle}</span> {show.title}</button>
				})}
			</div>
		</div>
	)
}
