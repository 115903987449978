import React, { useState, useEffect, useRef } from 'react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import Identify from '../utils/Identify'

// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import scrollToElement from 'scroll-to-element'
import Header from '../Header'
import Footer from '../Footer'
import SectionBar from './SectionBar'
import HomeVideoPlayer from './HomeVideoPlayer'
import Config from '../Config'
import VoteModule from './VoteModule'
import HomeHeader from './HomeHeader'

import ShowFlow, { findInFlow, flowIndex } from './ShowFlow'

import '../App.scss'

export default function Home () {
	const IdentifyInstance = new Identify()
	const ios = IdentifyInstance.mobileOS() === 'iOS'

	const [showBar, setShowBar] = useState(false)
	const [section, setSection] = useState(0)
	const [speeches, setSpeeches] = useState([])
	// const [currentVideoHash, setCurrentVideoHash] = useState(null);
	// const openingRef = useRef();
	const openingVideoRef = useRef()
	const currentSection = useRef(0)

	const go = () => {
		setSection(1)
		currentSection.current = 1
		console.log(document.querySelector('.video-stack').offsetHeight)
		scrollToElement('#section1', {
			offset: -(window.innerHeight * 0.5 - document.querySelector('#section1').offsetHeight * 0.5),
			duration: 1000
		}).on('end', () => {
			if (!ios) {
				document.querySelector('.video-trigger').click()
			}
		})
	}

	const stopAllVideos = () => {
		document.querySelectorAll('video').forEach(vid => vid.pause())
	}

	const setCurrentSection = (s) => {
		s = parseInt(s)
		currentSection.current = s
		setSection(s)
	}

	const playNext = (fiveWords) => {
		console.log('PLAY NEXT... current section == ' + currentSection.current)

		currentSection.current += 1
		playVideo(currentSection.current, false)
	}

	const playVideo = (videoNumber, fromBar) => {
		let counter = 1
		let found = false
		currentSection.current = videoNumber
		document.querySelectorAll('.video-trigger').forEach(vid => {
			if (currentSection.current === counter && !found) {
				found = true

				console.log('scrolling to ' + counter)
				scrollToElement(vid.parentNode, {
					offset: -(window.innerHeight * 0.5 - vid.parentNode.offsetHeight * 0.5),
					duration: 1000
				}).on('end', () => {
					if (!ios) {
						// from the bar and from ios
						// console.log('not going to play next');
						// } else {
						console.log('click next video')
						vid.click()
					}
				})
				setSection(currentSection.current)
			} else {
				counter++
			}
		})

		if (!found) {
			console.log('done')
		}
	}

	useScrollPosition(({ prevPos, currPos }) => {
		if (currPos.y < -window.innerHeight * 0.5) {
			setShowBar(true)
		} else {
			setShowBar(false)
		}
	})

	const getSpeeches = async () => {
		// const s = await axios.get(Config.endpoint + '/getAllVideos');
		// setSpeeches(s.data);
	}

	useEffect(() => {
		let c = 1
		document.querySelectorAll('.video-trigger').forEach(vid => {
			vid.setAttribute('data-id', c)
			c++
		})

		if (speeches.length === 0) {
			getSpeeches()
		}
	}, [])

	return (
		<>
			<Header color="black" />

			<div className="home">
				<HomeHeader go={go} />
				{/* <div ref={openingVideoRef} id="section1" className="home-section home-section-full bg-black">
					<div className="home-video-wrap home-square-video">
						<HomeVideoPlayer data={ShowFlow[0]} skipIntro={false} currentSection={section} setCurrentSection={setCurrentSection} onEnd={(fiveWords) => playNext(fiveWords)} />
					</div>
				</div> */}

				<div className="home-section home-section-full" id="section1">
					<div className="home-video-wrap home-video-wrap-wide">
						<HomeVideoPlayer data={findInFlow('monologue')} skipIntro={false} currentSection={section} setCurrentSection={setCurrentSection} onEnd={(fiveWords) => playNext(fiveWords)} />
					</div>
				</div>

				<div className="home-section home-section-grid home-square-video">
					{
						ShowFlow.map((show, s) => {
							if (s >= flowIndex('monologue') + 1 && s <= flowIndex('interview-hanifah') - 1) {
								return <HomeVideoPlayer key={s} data={show} skipIntro={show.videoID !== 'abba'} currentSection={section} setCurrentSection={setCurrentSection} onEnd={(fiveWords) => playNext(fiveWords)} />
							} else {
								return null
							}
						})
					}
				</div>

				<div className="home-section home-section-full">
					<div className="home-video-wrap home-video-wrap-wide">
						<HomeVideoPlayer data={findInFlow('interview-hanifah')} skipIntro={false} currentSection={section} setCurrentSection={setCurrentSection} onEnd={(fiveWords) => playNext(fiveWords)} />
					</div>
				</div>

				<div className="home-section home-section-grid home-square-video">
					{
						ShowFlow.map((show, s) => {
							if (s >= flowIndex('interview-hanifah') + 1 && s <= flowIndex('pinterest') - 1) {
								return <HomeVideoPlayer key={s} data={show} skipIntro={show.videoID !== 'adwoa'} currentSection={section} setCurrentSection={setCurrentSection} onEnd={(fiveWords) => playNext(fiveWords)} />
							} else {
								return null
							}
						})
					}
				</div>

				<div className="home-section home-section-full">
					<div className="home-video-wrap home-video-wrap-wide">
						<HomeVideoPlayer data={findInFlow('pinterest')} skipIntro={false} currentSection={section} setCurrentSection={setCurrentSection} onEnd={(fiveWords) => playNext(fiveWords)} />
					</div>
				</div>

				<div className="home-section home-section-grid home-square-video">
					{
						ShowFlow.map((show, s) => {
							if (s >= flowIndex('pinterest') + 1 && s <= flowIndex('interview-eliot') - 1) {
								return <HomeVideoPlayer key={s} data={show} skipIntro={false} currentSection={section} setCurrentSection={setCurrentSection} onEnd={(fiveWords) => playNext(fiveWords)} />
							} else {
								return null
							}
						})
					}
				</div>

				<div className="home-section home-section-full">
					<div className="home-video-wrap home-video-wrap-wide">
						<HomeVideoPlayer data={findInFlow('interview-eliot')} skipIntro={false} currentSection={section} setCurrentSection={setCurrentSection} onEnd={(fiveWords) => playNext(fiveWords)} />
					</div>
				</div>

				<div className="home-section home-section-grid home-square-video">
					{
						ShowFlow.map((show, s) => {
							if (s >= flowIndex('interview-eliot') + 1 && s <= flowIndex('message') - 1) {
								return <HomeVideoPlayer key={s} data={show} currentSection={section} setCurrentSection={setCurrentSection} onEnd={(fiveWords) => playNext(fiveWords)} />
							} else {
								return null
							}
						})
					}
				</div>

				<div className="home-section home-section-full">
					<div className="home-video-wrap home-video-wrap-wide">
						<HomeVideoPlayer data={findInFlow('message')} skipIntro={false} currentSection={section} setCurrentSection={setCurrentSection} onEnd={(fiveWords) => playNext(fiveWords)} />
					</div>
				</div>

				<div className="home-section home-section-grid home-square-video">
					{
						ShowFlow.map((show, s) => {
							if (s > flowIndex('message') && s < flowIndex('interview-christina')) {
								return <HomeVideoPlayer key={s} data={show} currentSection={section} setCurrentSection={setCurrentSection} onEnd={(fiveWords) => playNext(fiveWords)} />
							} else {
								return null
							}
						})
					}
				</div>

				<div className="home-section home-section-full">
					<div className="home-video-wrap home-video-wrap-wide">
						<HomeVideoPlayer data={findInFlow('interview-christina')} skipIntro={false} currentSection={section} setCurrentSection={setCurrentSection} onEnd={(fiveWords) => playNext(fiveWords)} />
					</div>
				</div>

				<div className="home-section home-section-grid home-square-video">
					{
						ShowFlow.map((show, s) => {
							if (s > flowIndex('interview-christina') && s < flowIndex('closing')) {
								return <HomeVideoPlayer key={s} data={show} currentSection={section} setCurrentSection={setCurrentSection} onEnd={(fiveWords) => playNext(fiveWords)} />
							} else {
								return null
							}
						})
					}
				</div>

				<div className="home-section home-section-full bg-room">
					<div className="home-video-wrap home-video-wrap-wide">
						<HomeVideoPlayer data={findInFlow('closing')} skipIntro={false} currentSection={section} setCurrentSection={setCurrentSection} onEnd={(fiveWords) => playNext(fiveWords)} />
					</div>
				</div>

				<Footer />

				<SectionBar section={section} show={showBar} onPlayVideo={(v) => playVideo(v, true)} />
			</div>
		</>
	)
}
