import React from 'react'
import VideoList from './VideoList'
import Help from './Help'

export default function ScreenWelcome ({ user = null, section, currentSection, setPiece }) {
	const setEntry = (e) => {
		setPiece(e)
	}

	return (
		<div className="entry-list">
			<div className="section">
				<h1>Hi, <span className="pink">{user ? user[0].Organization : null}</span></h1>
				<p>This year we're celebrating all 7 Words of Lovie speeches in a special gallery. Choose the work below to upload your speech.</p>

				<ul className="work-list">
					{user
						? user.map((project, p) => {
							return (<li key={p}>
								<button onClick={() => setEntry(project)}>
									<strong><u>{project.PieceTitle}</u></strong>
									{project.awards.map((award, a) => {
										return <div className="award-single" key={a}>
											{award.pv ? <div className="pv">{award.AwardLevel} / People's Lovie Winner</div> : <div className="pv">{award.AwardLevel}</div>}
											{award.MediaType} / {award.CategoryType} / {award.Category}
										</div>
									})}

								</button>
								<VideoList key={project.PiceID} pieceID={project.PieceID} />
							</li>)
						})
						: null}

				</ul>

				<Help />
			</div>
		</div>
	)
}
