import React from 'react'
import { Link } from 'react-router-dom'
import styles from './HomeHeader.module.scss'

const HomeHeader = ({ go }) => {
	return <div className={styles.root}>
		<div className={styles.vert}>
			<div>Welcome to the 11th Annual Lovie Awards &bull; Honoring the best of the European Internet</div>
			<br />
			<div>Hosted by:</div>
			<div className={styles.caps}>Deborah Frances-White</div>

			<div className={styles.biglogo}><img width="100%" src={require('../img/2021/reshape-possible.png').default} alt="Reshape Possible" /></div>

			<div>With 7 Words of Lovie Speeches and Appearances by:</div>
			<div className={styles.caps}>ABBA &bull; Richard Curtis &bull; Russell Brand &bull; Adwoa Aboah &bull; Mawaan Rizwan &bull; Eliot Higgins of Bellingcat</div>

			<div className={styles.buttons}>
				<div onClick={go} className={styles.button + ' SUPER-DUPER-HOVER-BUDDY'}>Play featured moments</div>
				<Link to="/browse"><div className={styles.button + ' SUPER-DUPER-HOVER-BUDDY'}>BROWSE ALL SPEECHES </div></Link>
			</div>
		</div>
	</div>
}

export default HomeHeader
