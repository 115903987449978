import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import Config from '../Config'
import Progress from './Progress'
import Identify from '../utils/Identify'
import Modal from 'react-modal'
import Analytics from '../Analytics'

import ShowVideoPlayer from './ShowVideoPlayer'
import VideoPlayer from '../browse/VideoPlayer'
import Twitter from '../img/twitter-white.svg'
import Facebook from '../img/facebook-white.svg'
import LinkOut from '../img/link.svg'

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)'
	}
}

export default function ScreenFinal ({ section, currentSection, pieceID = '', videoID, goHome }) {
	const [tweet, setTweet] = useState('')
	const identifyInstance = new Identify()
	const ios = identifyInstance.mobileOS() === 'iOS'
	const timeout = useRef()

	let newIOS = false

	if (ios) {
		newIOS = identifyInstance.newIOS()
	}

	const [waiting, setWaiting] = useState(true)
	const [showVideo, setShowVideo] = useState(false)
	const [info, setInfo] = useState(null)
	const [loading, setLoading] = useState(false)
	const [modal, setModal] = useState(false)
	const [dataURL, setDataURL] = useState(false)

	const checkShow = async () => {
		if (!videoID) return

		const data = await axios.get(Config.endpoint + '/getVideoLovies?videoID=' + videoID)
		// console.log(data.data);

		if (data.data.status === true) {
			setInfo(data.data)
			setWaiting(false)

			// console.log(data.data);
			const org = data.data.Twitter !== '' && data.data.Twitter ? '@' + data.data.Twitter : data.data.Organization
			// let presenter = data.data.PresenterTwitter !== '' && data.data.PresenterTwitter ? '@'+data.data.PresenterTwitter : data.data.Presenter;
			const t = `HUGE NEWS! 🙌 ${org} won a #Lovie! Check out my #7WordsOfLovie speech #Lovies`
			setTweet(t)
		} else {
			timeout.current = setTimeout(() => checkShow(), 5000)
		}
	}

	const FBShare = () => {
		window.open(
			'https://www.facebook.com/sharer.php?u=' + encodeURIComponent(Config.rootURL + '/speech/' + videoID),
			'fb share',
			'scrollbars=yes,resizable=yes,toolbar=no,location=yes,width=600,height=600'
		)
	}

	const downloadIOS = () => {
		const url = Config.bucketURL + info.hash + '-share.mp4'
		setModal(true)
		if (!newIOS) {
			getVideoStream(url)
		}
	}

	const getBase64 = (blob) => {
		return new Promise(function (resolve) {
			const reader = new FileReader()
			reader.onloadend = function () {
				resolve(reader.result)
			}
			reader.readAsDataURL(blob)
		})
	}

	const getVideoStream = (url) => {
		// console.log(url);
		const result = []
		fetch(url).then(response => {
			const reader = response.body.getReader()
			reader.read().then(function processText ({ done, value }) {
				if (done) {
					const blob = new Blob(result, { type: 'video/mp4' })
					getBase64(blob).then(function (result) {
						setDataURL(result)
						setLoading(false)
					})
					return
				}

				// console.log(value);
				const chunk = value
				result.push(chunk)

				return reader.read().then(processText)
			})
		})
	}

	useEffect(() => {
		Modal.setAppElement('#root')
		setTimeout(() => checkShow(), 1000)
		Analytics.sendCreatedEvent(videoID, pieceID)

		return () => clearTimeout(timeout.current)
	}, [])

	return (
		<>
			{!showVideo
				? (
					<div className="section-final flex force-flex">
						<div>
							<div>
								<div className="section-final-wide-video">
									<h1>A message from The Lovies</h1>
									<VideoPlayer poster={Config.bucketURL + 'nick_nik_2021.jpg'} src={Config.bucketURL + 'nick_nik_2021-02.mp4'} autoPlay loop={false} />
								</div>

								<br /><br />
								{waiting
									? (
										<>
											<div>Your official speech video is being compiled, give us 2-3 minutes</div>
											<div className="wait-bar">
												<div className="wait-bar-inner"></div>
											</div>
										</>
									)
									: <button onClick={() => setShowVideo(true)} style={{ color: '#f7ee5e' }}>See My Video</button>}
							</div>
						</div>
					</div>
				)
				: (
					<div className="section-final flex">
						<div>
							<div className="video">
								{/* <ShowVideoPlayer pieceID={info.PieceID} hash={info.hash} /> */}
								{currentSection === 5 ? <VideoPlayer poster={Config.bucketURL + info.hash + '.jpg'} src={ Config.bucketURL + info.hash + '-share.mp4'} autoPlay /> : null}
							</div>
							<div className="section-final-message message-text">
								<p>
                                    Here's your 7 Words of Lovie Speech video! All winner speeches including Special
                                    Achievement will be available to watch and share at{' '}
									<a href="https://watch.lovieawards.com" className="pink" rel="noreferrer" target="_blank">watch.lovieawards.com</a>{' '}
                                    starting at 14:00GMT on 17 November. For now, please just make sure you're happy with your speech video, and if you
                                    need to make a change, you can upload again.</p>
								<p className="pink"><strong>But remember:</strong> no sharing on social until the 17th!</p>
							</div>
						</div>

						{/* <div className="video-buttons">

                        ios ?
                            <button onClick={downloadIOS}><span>Share your 5-Word Speech</span>on Social Using #lovies</button>
                            :
                            <a href={'/download?file='+ encodeURIComponent(info.hash + '-share.mp4')} className="a-button" download="my-webby-from-home.mp4" target="_blank" rel="noopener noreferrer"><span>Share your 5-Word Speech</span>on Social Using #Webbys</a>

                        <div className="side-by-side">
                            <div><a className="twitter-share-button" href={"https://twitter.com/intent/tweet?text="+encodeURIComponent(tweet)+"&via=thewebbyawards&hashtags=webby,5WordSpeech&url="+encodeURIComponent(Config.rootURL + '/speech/'+videoID)}><img src={Twitter} alt="Share to Twitter" /></a></div>
                            <div><a href="#" onClick={()=>FBShare()}><img src={Facebook} alt="Share to Facebook" /></a></div>
                            <div><a href={'/speech/' + videoID}><img src={LinkOut} alt="Go to video" /></a></div>
                        </div>
                    </div> */}
					</div>
				)}

			<Progress progress={4} />

			<Modal
				isOpen={modal}
				style={customStyles}
			>
				<div className="modal-inner">
					<button onClick={() => setModal(false)} className="close-btn">Close</button>
					<h3>iOS is a bit weird.</h3>
                    To save your file to your camera roll:

					<ol className="save-instructions-list">
						<li>Hit "Download" below.</li>
						<li>Tap the down arrow icon in the address bar.</li>
						<li>Open the video.</li>
						<li>Tap the share icon on the bottom of your screen.</li>
						<li>Select 'Save video' to save to camera roll.</li>
						<li>Sigh. Share it to social media, you put in enough effort.</li>
					</ol>

					{
						newIOS
							? <a href={'/download?file=' + encodeURIComponent(info.hash + '-share.mp4')} className="a-button" download="my-webby-from-home.mp4" target="_blank" rel="noopener noreferrer"><span>Download</span>Your 5-Word Speech</a>
							: <a href={dataURL || '#'} className="a-button" download="my-webby-from-home.mp4" target="_blank" rel="noopener noreferrer">Download</a>
					}
				</div>
			</Modal>
		</>
	)
}
