import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import firebase from 'firebase/app'
import 'firebase/firestore'

import Config from '../Config'
import axios from 'axios'
import Header from '../Header'
import Footer from '../Footer'
import VideoPlayer from './VideoPlayer'
import ShowVideoPlayer from '../recorder/ShowVideoPlayer'
import Loader from '../recorder/Loader'
import Twitter from '../img/twitter-white.svg'
import Facebook from '../img/facebook-white.svg'
import DownloadImg from '../img/download.svg'

const firebaseConfig = {
	apiKey: 'AIzaSyBYWeaP4EOl0gVh59sj-d0BgSnOpJhQaHg',
	authDomain: 'webbyreport-811f9.firebaseapp.com',
	databaseURL: 'https://webbyreport-811f9.firebaseio.com',
	projectId: 'webbyreport-811f9',
	storageBucket: 'webbyreport-811f9.appspot.com',
	messagingSenderId: '1029956129168',
	appId: '1:1029956129168:web:b865f4a6d14ef5bf29e315'
}

if (!firebase.apps.length) {
	firebase.initializeApp(firebaseConfig)
}

const db = firebase.firestore()

export default function Speech ({ full = false }) {
	const [tweet, setTweet] = useState('')
	const { id } = useParams()
	const [video, setVideo] = useState(null)
	const [wide, setWide] = useState(false)

	const getStuff = async () => {
		/* let endpoint = 'https://us-central1-webbyreport-811f9.cloudfunctions.net/getVideoLovies?videoID='+id;

        if(full === true) {
            // get full speech video
            endpoint = Config.endpoint + '/getVideoByPieceLovies?pieceID='+id;
        }
        const v = await axios.get( endpoint); */

		const v = await db.collection('lovies_videos_2021').doc(id).get()
		if (v.exists) {
			setVideo(v.data())

			const org = v.data().Twitter !== '' && v.data().Twitter ? '@' + v.data().Twitter : v.data().Organization

			// let presenter = v.data.PresenterTwitter !== '' && v.data.PresenterTwitter ? '@'+v.data.PresenterTwitter : v.data.Presenter;

			let t = `HUGE NEWS! 🙌 ${org} won a #Lovie! Check out my #7WordsOfLovie speech #Lovies`
			// if (org === 'Nishma Robb, Google') {
			// 	t = 'Watch a special congratulations message from @NishmaRobb'
			// }

			// if (org === 'Imogen Heap Presents') {
			// 	t = 'HUGE NEWS! 🙌 Imogen Heap Presents @gorillaz with a Lovie Lifetime Achievement Award! Check out their #7WordsOfLovie speech'
			// }

			if (id === 'abba') {
				t = 'HUGE NEWS! 🙌  Richard Curtis presents @ABBA with a Lovie Lifetime Achievement Award! Check out their #7WordsOfLovie speech #Lovies https://watch.lovieawards.com/watch/abba via @lovieawards'
			}

			// Adwoa Aboah	HUGE NEWS! 🙌  @AdwoaAboah_ won a Lovie Special Achievement Award for @GurlsTalk! Check out her #7WordsOfLovie speech https://watch.lovieawards.com/watch/abba #Lovies #7WordsOfLovie via @lovieawards
			if (id === 'mawaan') {
				t = 'HUGE NEWS! 🙌  @mawaanr won a Lovie Special Achievement Award! Check out his #7WordsOfLovie speech #Lovies https://watch.lovieawards.com/watch/mawaan #7WordsOfLovie via @lovieawards'
			}

			if (id === 'eliot Higgins') {
				t = 'HUGE NEWS! 🙌  @EliotHiggins of @bellingcat won a Lovie Special Achievement Award! Check out his #7WordsOfLovie speech https://watch.lovieawards.com/watch/eliot #Lovies #7WordsOfLovie via @lovieawards'
			}

			if (id === 'mediamonks') {
				t = 'HUGE NEWS! 🙌  @meetthemonks won Lovie Agency of the Year, presented by @deborahfw! Check out their #7WordsOfLovie speech https://watch.lovieawards.com/watch/mediamonks #Lovies #7WordsOfLovie via @lovieawards'
			}

			if (id === 'pinterest') {
				t = 'Watch this special congratulations from @PinterestUK’s @vishanaul : https://watch.lovieawards.com/watch/pinterest'
			}

			if (id === 'interview-christina') {
				t = 'Check out @DeborahFW\'s interview with @knightan of the @amazingsociety about Lovie-winning series \'the Power of a Verb!\' at watch.lovieawards.com/watch/interview-christina 🗣️  #ReshapePossible'
			}

			if (id === 'interview-Eliot') {
				t = 'In this exclusive interview, Lovie host @DeborahFW speaks to Special Achievement Award winner @EliotHiggins of @Bellingcat about the evolution of online investigative journalism. Watch & learn about why it\'s so important in today\'s world: watch.lovieawards.com/watch/interview-eliot'
			}

			if (id === 'interview-hanifah') {
				t = 'Lovie Awards host @DeborahFW gets up close and personal with @buzzfeed\'s Seasoned Sessions podcast Co-Host @its_hanifahh, about sharing Black British stories. Check out the full interview now at watch.lovieawards.com/watch/interview-hanifah'
			}

			if (id === 'monologue')	{
				t = 'Check out @deborahFW\'s fantastically hilarious intro to the 11th Annual Lovie Awards: Reshape Possible, and enjoy all of this year\'s amazing winners! https://watch.lovieawards.com/watch/monologue'
			}

			if (id === 'closing') {
				t = 'Check out @deborahFW\'s fun wrap-up to the 11th Annual Lovie Awards: Reshape Possible https://watch.lovieawards.com/watch/closing'
			}

			setTweet(t)
		}
	}

	const FBShare = () => {
		window.open(
			'https://www.facebook.com/sharer.php?u=' + encodeURIComponent(Config.rootURL + '/watch/' + id),
			'fb share',
			'scrollbars=yes,resizable=yes,toolbar=no,location=yes,width=600,height=600'
		)
	}

	useEffect(() => {
		getStuff()
		if (id === 'moses' || id === 'monologue' || id === 'closing') {
			setWide(true)
		}
	}, [])

	return (
		<>
			<Header />
			<div className={wide ? 'speech-page speech-page-wide' : 'speech-page'}>
				{video
					? (
						<div className="speech-page-inner">
							{full
								? <ShowVideoPlayer pieceID={video.PieceID} hash={video.hash} />
								: <VideoPlayer videoID={id} poster={Config.bucketURL + video.hash + '.jpg'} src={Config.bucketURL + video.hash + '-share.mp4'} loop={false} />
							}
							<div className="speech-info">
								<h2 className="pink">{video.OrganizationUrl ? <a href={video.OrganizationUrl} target="_blank" rel="noopener noreferrer">{video.Organization}</a> : video.Organization}</h2>
								{video.PieceTitle ? (<><h1>{video.PieceTitle}</h1></>) : null}
								{video.awards
									? video.awards.map((award, a) => {
										return <div className="award-single" key={a}>
											{award.pv ? <div className="pv">{award.AwardLevel} / People's Lovie Winner</div> : <div className="pv">{award.AwardLevel.toUpperCase()}</div>}
											<div className="award-single-sub">{award.MediaType} / {award.CategoryType} / {award.Category}</div>
										</div>
									})
									: null}
								{video.GalleryUrl ? <><a href={video.GalleryUrl} target="_blank" rel="noopener noreferrer" className="work-url">View Work</a><br /></> : null}

								<div className="side-by-side">
									{/* <div><a href="https://www.pinterest.com/pin/create/button/" data-pin-do="buttonBookmark">
										<img src={require('../img/pinterest-badge-white.svg').default} />
									</a></div> */}
									<div><a className="twitter-share-button" href={'https://twitter.com/intent/tweet?text=' + encodeURIComponent(tweet) + '&via=lovieawards&hashtags=Lovies,7WordsOfLovie&url=' + encodeURIComponent(Config.rootURL + '/watch/' + id)}><img src={Twitter} alt="Share to Twitter" /></a></div>
									<div><a href="#" onClick={() => FBShare()}><img src={Facebook} alt="Share to Facebook" /></a></div>
								</div>
							</div>
						</div>
					)
					: <Loader text="Loading Video" />}
			</div>
			<Footer />
		</>
	)
}
