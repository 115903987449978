const flow = [
	{
		title: 'Deborah Frances-White',
		subtitle: 'A Lovie Welcome From',

		hash: 'WIP_Lovie_11_Host_Intro',
		videoID: 'monologue',
		presenter: null,
		winner: null,
		prefix: '',

		needsWatch: true
	},
	{
		title: 'ABBA',
		subtitle: 'Richard Curtis Presents To',

		hash: 'abba_sa',
		videoID: 'abba',
		presenter: null,
		winner: null,
		prefix: 'Lifetime Achievement',

		needsWatch: true
	},
	{
		title: 'Luminary: Under the Skin with Russell Brand',
		subtitle: 'Russell Brand For',

		hash: 'b2b6b303-da9d-4504-b1cb-096b0edaebc5',
		videoID: 'BOiCDb3449dtEr3Elz33',
		presenter: null,
		winner: null,
		prefix: 'Bronze & People\'s Lovie | Best Podcast Host'
	},
	{
		title: 'Hanifah Rahman of Buzzfeed',
		subtitle: '7 Questions With',

		hash: 'interview_hanifah_rahman',
		videoID: 'interview-hanifah',
		presenter: null,
		winner: null,
		prefix: 'A Brief Lovie Interview',

		needsWatch: true
	},
	{
		title: 'Adwoa Aboah',
		subtitle: 'Deborah Frances-White Presents To',

		hash: 'sa_adwoa',
		videoID: 'adwoa',
		presenter: null,
		winner: null,
		prefix: 'Special Achievement'
	},
	{
		title: 'Content is Queen',
		subtitle: 'Imriel Morgan & Team for',

		hash: '2c12899e-3316-4b37-9c83-5d06d072a899',
		videoID: 'HkEEL4hPSnGn7X148mdZ',
		presenter: null,
		winner: null,
		prefix: 'Gold | Podcasts - Diversity, Equity & Inclusion'
	},
	{
		title: 'Pinterest',
		subtitle: 'A Message From',

		hash: 'pinterest',
		videoID: 'pinterest',
		presenter: null,
		winner: null,
		prefix: 'Congratulations',

		needsWatch: true
	},
	{
		title: 'Mawaan Rizwan',
		subtitle: 'Thanyia Moore Presents To',

		hash: 'sa_mawaan_rizwan',
		videoID: 'mawaan',
		presenter: null,
		winner: null,
		prefix: 'Special Achievement',

		needsWatch: true
	},
	{
		title: 'Eliot Higgins, Founder of Bellingcat',
		subtitle: 'Deborah Frances-White Presents To',

		hash: 'eliot_sa_video1',
		videoID: 'eliot',
		presenter: null,
		winner: null,
		prefix: 'Special Achievement',

		needsWatch: true
	},
	{
		title: 'Eliot Higgins of Bellingcat',
		subtitle: '7 Questions With',

		hash: 'interview_eliot',
		videoID: 'interview-eliot',
		presenter: null,
		winner: null,
		prefix: 'A Brief Lovie Interview',

		needsWatch: true
	},
	{
		title: 'Al Jazeera Digital',
		subtitle: 'Sandra Gathmann, Host of Start Here',

		hash: '6ac0399c-4ead-447c-b890-151eebc1f28b',
		videoID: '5Kqg3gPsLW2Xg8LaDesO',
		presenter: null,
		winner: null,
		prefix: 'Silver & People\'s Lovie | Best Use of Video'
	},
	{
		title: 'Channel4',
		subtitle: 'Rosie Jones For',

		hash: '788110cf-94ef-4e26-8d7a-20aaf4e313cf',
		videoID: 'BhhKXGLtPQtlmelKIdSx',
		presenter: null,
		winner: null,
		prefix: 'Bronze & People\'s Lovie | Branded Diversity, Equity & Inclusion'
	},
	{
		title: 'The Lovie Awards',
		subtitle: 'A Message From',

		hash: 'nick_nik_2021_landing',
		videoID: 'message',
		presenter: null,
		winner: null,
		prefix: '',

		needsWatch: true
	},
	{
		title: 'Cheil Germany GmbH',
		subtitle: 'Bruno Hoffman & Felix Prangenberg For',

		hash: '2b95de8b-6084-42c5-9691-58711240a6df',
		videoID: 'WcEoZVTYXK2DW5K7ph5e',
		presenter: null,
		winner: null,
		prefix: 'Bronze & People\'s Lovie | Travel & Lifestyle'
	},
	{
		title: 'Media.Monks B.V.',
		subtitle: 'Deborah Frances-White Presents To',

		hash: 'media_monks_sa_video',
		videoID: 'mediamonks',
		presenter: null,
		winner: null,
		prefix: 'Lovie Agency of the Year',

		needsWatch: true
	},
	{
		title: 'Christina Knight of The Amazing Society',
		subtitle: '7 Questions With',

		hash: 'interview_christina',
		videoID: 'interview-christina',
		presenter: null,
		winner: null,
		prefix: 'A Brief Lovie Interview',

		needsWatch: true
	},
	{
		title: 'Yune',
		subtitle: 'Bram Krikke For',

		hash: 'db8a08a0-4348-41b9-bccf-fb3bbb8d97d7',
		videoID: 'S9aE3Kf7PyBTFEgMG6vG',
		presenter: null,
		winner: null,
		prefix: 'Bronze | Social Video Series'
	},
	{
		title: 'POL Oslo',
		subtitle: 'Kristofer Hivju For',

		hash: '74be79c1-319c-4644-8c6c-a69063bc89e1',
		videoID: 'eQrdvXs0kezOBQ5Y5cRW',
		presenter: null,
		winner: null,
		prefix: 'Gold | Best Event Activation'
	},
	{
		title: 'Deborah Frances-White',
		subtitle: 'A Lovie Farewell From',

		hash: 'WIP_Lovie_11_Host_Close',
		videoID: 'closing',
		presenter: null,
		winner: null,
		prefix: '',

		needsWatch: true
	}
]

// module.exports = flow
export default flow

export function flowIndex (id) {
	return flow.indexOf(findInFlow(id))
}

export function findInFlow (id) {
	return flow.find(v => v.videoID === id)
}
