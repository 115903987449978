import React, { useState } from 'react'
import Identify from '../utils/Identify'
// import VideoCapture from './VideoCapture/VideoCapture';
import Progress from './Progress'
import Analytics from '../Analytics'
import Help from './Help'

export default function ScreenRecorder ({ piece = null, section, currentSection, setShow, official }) {
	const identifyInstance = new Identify()
	const ios = identifyInstance.mobileOS() === 'iOS' || identifyInstance.mobileOS() === 'Android'
	const safari = identifyInstance.safari()
	const [enable, setEnable] = useState(null)

	const [progress, setProgress] = useState(1)

	const finalize = (videoID) => {
		setShow(videoID)
	}

	return (
		<div className="section-recorder flex">
			<div className="section-wide recorder">
				<div>
					<div className="flex">
						<div className="p-top">
							<h1>Upload speech for <div className="strong pink">{piece ? piece.PieceTitle : null}</div></h1>
							<p><strong>Here's whats going to happen:</strong></p>
							<p>Upload your speech here and it will be posted to our gallery for the world to see. Don't forget to be creative with your 7 Words of Lovie!</p>

							{ios || safari
								? <button onClick={() => setShow()} style={{ color: '#f7ee5e' }}>Let's Do it</button>
								: (
									<div className="button-choose">
										<button onClick={() => setShow()} style={{ color: '#f7ee5e' }}>Upload a video</button>
									</div>
								)
							}
							<br />
							<br />
							<Help />
						</div>
					</div>
				</div>
			</div>
			<Progress progress={progress} />
		</div>
	)
}
