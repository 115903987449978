import React from 'react'
// import loader from '../img/heart.svg';
import styles from './Loader.module.scss'

export default function Loader ({ text = 'Uploading your speech<br />Give it a minute' }) {
	return (
		<div className={styles.root}>
			<div className={styles.inner}>
				{/* <img src={loader} alt="loading" /> */}
				<svg viewBox="0 0 130 110" version="1.1" xmlns="http://www.w3.org/2000/svg">
					<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
						<g id="LOVIE_lock-up_2020" transform="translate(-118.000000, 0.000000)" fill="#f7ee5e">
							<g id="heart" transform="translate(118.000000, 0.000000)">
								<polygon id="Fill-106" points="20.925 9.214 29.214 9.214 29.214 0.925 20.925 0.925"></polygon>
								<polygon id="Fill-107" points="101.143 9.214 109.432 9.214 109.432 0.925 101.143 0.925"></polygon>
								<polygon id="Fill-108" points="91.115 9.214 99.404 9.214 99.404 0.925 91.115 0.925"></polygon>
								<polygon id="Fill-109" points="81.088 9.214 89.377 9.214 89.377 0.925 81.088 0.925"></polygon>
								<polygon id="Fill-110" points="40.979 9.214 49.268 9.214 49.268 0.925 40.979 0.925"></polygon>
								<polygon id="Fill-111" points="30.952 9.214 39.24 9.214 39.24 0.925 30.952 0.925"></polygon>
								<polygon id="Fill-112" points="30.952 19.241 39.24 19.241 39.24 10.952 30.952 10.952"></polygon>
								<polygon id="Fill-113" points="30.952 29.269 39.24 29.269 39.24 20.98 30.952 20.98"></polygon>
								<polygon id="Fill-114" points="30.952 39.296 39.24 39.296 39.24 31.007 30.952 31.007"></polygon>
								<polygon id="Fill-115" points="30.952 49.323 39.24 49.323 39.24 41.034 30.952 41.034"></polygon>
								<polygon id="Fill-116" points="30.952 59.35 39.24 59.35 39.24 51.061 30.952 51.061"></polygon>
								<polygon id="Fill-117" points="30.952 69.377 39.24 69.377 39.24 61.088 30.952 61.088"></polygon>
								<polygon id="Fill-118" points="40.979 19.241 49.268 19.241 49.268 10.952 40.979 10.952"></polygon>
								<polygon id="Fill-119" points="40.979 29.269 49.268 29.269 49.268 20.98 40.979 20.98"></polygon>
								<polygon id="Fill-120" points="40.979 39.296 49.268 39.296 49.268 31.007 40.979 31.007"></polygon>
								<polygon id="Fill-121" points="40.979 49.323 49.268 49.323 49.268 41.034 40.979 41.034"></polygon>
								<polygon id="Fill-122" points="40.979 59.35 49.268 59.35 49.268 51.061 40.979 51.061"></polygon>
								<polygon id="Fill-123" points="40.979 69.377 49.268 69.377 49.268 61.088 40.979 61.088"></polygon>
								<polygon id="Fill-124" points="40.979 79.405 49.268 79.405 49.268 71.116 40.979 71.116"></polygon>
								<polygon id="Fill-125" points="51.007 19.241 59.296 19.241 59.296 10.952 51.007 10.952"></polygon>
								<polygon id="Fill-126" points="51.007 29.269 59.296 29.269 59.296 20.98 51.007 20.98"></polygon>
								<polygon id="Fill-127" points="51.007 39.296 59.296 39.296 59.296 31.007 51.007 31.007"></polygon>
								<polygon id="Fill-128" points="51.007 49.323 59.296 49.323 59.296 41.034 51.007 41.034"></polygon>
								<polygon id="Fill-129" points="51.007 59.35 59.296 59.35 59.296 51.061 51.007 51.061"></polygon>
								<polygon id="Fill-130" points="51.007 69.377 59.296 69.377 59.296 61.088 51.007 61.088"></polygon>
								<polygon id="Fill-131" points="51.007 79.405 59.296 79.405 59.296 71.116 51.007 71.116"></polygon>
								<polygon id="Fill-132" points="51.007 89.432 59.296 89.432 59.296 81.143 51.007 81.143"></polygon>
								<polygon id="Fill-133" points="61.034 29.269 69.322 29.269 69.322 20.98 61.034 20.98"></polygon>
								<polygon id="Fill-134" points="61.034 39.296 69.322 39.296 69.322 31.007 61.034 31.007"></polygon>
								<polygon id="Fill-135" points="61.034 49.323 69.322 49.323 69.322 41.034 61.034 41.034"></polygon>
								<polygon id="Fill-136" points="61.034 59.35 69.322 59.35 69.322 51.061 61.034 51.061"></polygon>
								<polygon id="Fill-137" points="61.034 69.377 69.322 69.377 69.322 61.088 61.034 61.088"></polygon>
								<polygon id="Fill-138" points="61.034 79.405 69.322 79.405 69.322 71.116 61.034 71.116"></polygon>
								<polygon id="Fill-139" points="61.034 89.432 69.322 89.432 69.322 81.143 61.034 81.143"></polygon>
								<polygon id="Fill-140" points="61.034 99.459 69.322 99.459 69.322 91.17 61.034 91.17"></polygon>
								<polygon id="Fill-141" points="61.034 109.486 69.322 109.486 69.322 101.197 61.034 101.197"></polygon>
								<polygon id="Fill-142" points="71.061 19.241 79.35 19.241 79.35 10.952 71.061 10.952"></polygon>
								<polygon id="Fill-143" points="71.061 29.269 79.35 29.269 79.35 20.98 71.061 20.98"></polygon>
								<polygon id="Fill-144" points="71.061 39.296 79.35 39.296 79.35 31.007 71.061 31.007"></polygon>
								<polygon id="Fill-145" points="71.061 49.323 79.35 49.323 79.35 41.034 71.061 41.034"></polygon>
								<polygon id="Fill-146" points="71.061 59.35 79.35 59.35 79.35 51.061 71.061 51.061"></polygon>
								<polygon id="Fill-147" points="71.061 69.377 79.35 69.377 79.35 61.088 71.061 61.088"></polygon>
								<polygon id="Fill-148" points="71.061 79.405 79.35 79.405 79.35 71.116 71.061 71.116"></polygon>
								<polygon id="Fill-149" points="71.061 89.432 79.35 89.432 79.35 81.143 71.061 81.143"></polygon>
								<polygon id="Fill-150" points="81.088 19.241 89.377 19.241 89.377 10.952 81.088 10.952"></polygon>
								<polygon id="Fill-151" points="81.088 29.269 89.377 29.269 89.377 20.98 81.088 20.98"></polygon>
								<polygon id="Fill-152" points="81.088 39.296 89.377 39.296 89.377 31.007 81.088 31.007"></polygon>
								<polygon id="Fill-153" points="81.088 49.323 89.377 49.323 89.377 41.034 81.088 41.034"></polygon>
								<polygon id="Fill-154" points="81.088 59.35 89.377 59.35 89.377 51.061 81.088 51.061"></polygon>
								<polygon id="Fill-155" points="81.088 69.377 89.377 69.377 89.377 61.088 81.088 61.088"></polygon>
								<polygon id="Fill-156" points="81.088 79.405 89.377 79.405 89.377 71.116 81.088 71.116"></polygon>
								<polygon id="Fill-157" points="91.115 19.241 99.404 19.241 99.404 10.952 91.115 10.952"></polygon>
								<polygon id="Fill-158" points="91.115 29.269 99.404 29.269 99.404 20.98 91.115 20.98"></polygon>
								<polygon id="Fill-159" points="91.115 39.296 99.404 39.296 99.404 31.007 91.115 31.007"></polygon>
								<polygon id="Fill-160" points="91.115 49.323 99.404 49.323 99.404 41.034 91.115 41.034"></polygon>
								<polygon id="Fill-161" points="91.115 59.35 99.404 59.35 99.404 51.061 91.115 51.061"></polygon>
								<polygon id="Fill-162" points="91.115 69.377 99.404 69.377 99.404 61.088 91.115 61.088"></polygon>
								<polygon id="Fill-163" points="121.197 29.269 129.485 29.269 129.485 20.98 121.197 20.98"></polygon>
								<polygon id="Fill-164" points="121.197 39.296 129.485 39.296 129.485 31.007 121.197 31.007"></polygon>
								<polygon id="Fill-165" points="121.197 49.323 129.485 49.323 129.485 41.034 121.197 41.034"></polygon>
								<polygon id="Fill-166" points="101.143 19.241 109.432 19.241 109.432 10.952 101.143 10.952"></polygon>
								<polygon id="Fill-167" points="101.143 29.269 109.432 29.269 109.432 20.98 101.143 20.98"></polygon>
								<polygon id="Fill-168" points="101.143 39.296 109.432 39.296 109.432 31.007 101.143 31.007"></polygon>
								<polygon id="Fill-169" points="101.143 49.323 109.432 49.323 109.432 41.034 101.143 41.034"></polygon>
								<polygon id="Fill-170" points="101.143 59.35 109.432 59.35 109.432 51.061 101.143 51.061"></polygon>
								<polygon id="Fill-171" points="101.143 69.377 109.432 69.377 109.432 61.088 101.143 61.088"></polygon>
								<polygon id="Fill-172" points="111.17 19.241 119.459 19.241 119.459 10.952 111.17 10.952"></polygon>
								<polygon id="Fill-173" points="111.17 29.269 119.459 29.269 119.459 20.98 111.17 20.98"></polygon>
								<polygon id="Fill-174" points="111.17 39.296 119.459 39.296 119.459 31.007 111.17 31.007"></polygon>
								<polygon id="Fill-175" points="111.17 49.323 119.459 49.323 119.459 41.034 111.17 41.034"></polygon>
								<polygon id="Fill-176" points="111.17 59.35 119.459 59.35 119.459 51.061 111.17 51.061"></polygon>
								<polygon id="Fill-177" points="10.898 19.241 19.187 19.241 19.187 10.952 10.898 10.952"></polygon>
								<polygon id="Fill-178" points="10.898 29.269 19.187 29.269 19.187 20.98 10.898 20.98"></polygon>
								<polygon id="Fill-179" points="10.898 39.296 19.187 39.296 19.187 31.007 10.898 31.007"></polygon>
								<polygon id="Fill-180" points="10.898 49.323 19.187 49.323 19.187 41.034 10.898 41.034"></polygon>
								<polygon id="Fill-181" points="10.898 59.35 19.187 59.35 19.187 51.061 10.898 51.061"></polygon>
								<polygon id="Fill-182" points="20.925 29.269 29.214 29.269 29.214 20.98 20.925 20.98"></polygon>
								<polygon id="Fill-183" points="20.925 39.296 29.214 39.296 29.214 31.007 20.925 31.007"></polygon>
								<polygon id="Fill-184" points="20.925 49.323 29.214 49.323 29.214 41.034 20.925 41.034"></polygon>
								<polygon id="Fill-185" points="20.925 59.35 29.214 59.35 29.214 51.061 20.925 51.061"></polygon>
								<polygon id="Fill-186" points="20.925 69.377 29.214 69.377 29.214 61.088 20.925 61.088"></polygon>
								<polygon id="Fill-187" points="0.87 29.269 9.16 29.269 9.16 20.98 0.87 20.98"></polygon>
								<polygon id="Fill-188" points="0.87 39.296 9.16 39.296 9.16 31.007 0.87 31.007"></polygon>
								<polygon id="Fill-189" points="0.87 49.323 9.16 49.323 9.16 41.034 0.87 41.034"></polygon>
							</g>
						</g>
					</g>
				</svg>

				<span className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
			</div>
		</div>
	)
}
