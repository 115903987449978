import React, { useState, useRef, useEffect } from 'react'
import { Player, ControlBar } from 'video-react'
import Analytics from '../Analytics'
import playImg from '../img/playImg.svg'
import pauseImg from '../img/pauseImg.svg'
import restartImg from '../img/restartImg.svg'

export default function VideoPlayer ({ src, poster, loop = true, videoID = '', fluid = true, onEnd = null, fiveWords = false, autoPlay = true, preload = 'auto' }) {
	const video = useRef()
	const [playing, setPlaying] = useState(false)
	const [finished, setFinished] = useState(false)
	const [playbar, setPlaybar] = useState(0)
	const playVideo = () => {
		console.log('hi')
	}
	const handleChange = (state, prevState) => {
		const d = Math.floor((100 / state.duration) * state.currentTime)
		setPlaybar(d)

		if (state.ended === true && prevState.ended === false) {
			if (loop) {
				video.current.play()
			}

			setPlaying(false)
			setFinished(true)
			if (onEnd) {
				onEnd(fiveWords)
			}
		}

		if (state.hasStarted && !prevState.hasStarted) {
			setPlaying(true)
		}
	}

	const play = () => {
		setPlaying(true)
		video.current.play()
	}

	const pause = () => {
		setPlaying(false)
		video.current.pause()
	}

	const restart = () => {
		setPlaying(true)
		setFinished(false)
		video.current.play()
	}

	useEffect(() => {
		video.current.subscribeToStateChange(handleChange)
		Analytics.videoView(videoID)
	}, [])

	return (
		<div className="video-stack">
			<Player poster={poster} ref={video} playsInline autoPlay={autoPlay} preload={preload} fluid={fluid} width={'100%'} height={'100%'}>
				<source src={src} />
				<ControlBar disableCompletely={false} />
			</Player>
			{/* <div className="playbar">
                <div className="playbar-inner" style={{width: playbar + '%'}}></div>
            </div> */}
			<div className={playing ? 'video-controls video-controls-playing' : 'video-controls'}>
				{!playing && !finished
					? <button onClick={play}>
						<div className="video-button-inner"><img src={playImg} alt="Play" /></div>
					</button>
					: null }
				{playing
					? <button onClick={pause}>
						<div className="video-button-inner"><img src={pauseImg} alt="Pause" /></div>
					</button>
					: null }
				{finished
					? <button onClick={restart}>
						<div className="video-button-inner"><img src={restartImg} alt="Restart" /></div>
					</button>
					: null }
			</div>
		</div>
	)
}
