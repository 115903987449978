import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import heart from './img/2021/header-logo.png'
import sponsorLogo from './img/2021/pinterest.svg'
import styles from './Header.module.scss'
import c from 'classnames'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

export default function Header ({ section = 'main' }) {
	const [url, setURL] = useState(null)
	const [text, setText] = useState(null)
	const [opacity, setOpacity] = useState(0)
	const location = useLocation()

	useEffect(() => {
		if (location.pathname === '/browse' || location.pathname.indexOf('/browse') !== -1 || location.pathname.indexOf('/search') !== -1) {
			setURL('/')
			setText('Featured Moments')
		} else {
			setURL('/browse')
			setText('All Speeches')
		}
	}, [location])

	useScrollPosition(({ prevPos, currPos }) => {
		let opacity = currPos.y / -200
		opacity = opacity > 1 ? 1 : opacity
		if (opacity <= 1) {
			setOpacity(opacity)
		}
	})

	return <>
		<div className={styles.bg} style={{ opacity }} />
		<header className={styles.root}>
			<Link to="/" className={c(styles.logo, { [styles.browseLogo]: section === 'browse' })}>
                &nbsp;
			</Link>

			<div className={styles.links}>
				{url ? <Link to={url} className={styles.navLink}>{text}</Link> : null}

				<a href="https://business.pinterest.com/en-gb/" target="_blank" rel="noopener noreferrer" className={styles.pinterest}>
					<div>
                        PRESENTED BY:
						<img src={sponsorLogo} alt="Pinterest" />
					</div>
				</a>
			</div>
		</header>
	</>
}
