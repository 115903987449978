function Identify () {
	const publicAPI = {
		mobileOS,
		browser,
		newIOS,
		safari
	}
	return publicAPI

	function mobileOS () {
		const userAgent = navigator.userAgent || navigator.vendor || window.opera

		if (/windows phone/i.test(userAgent)) {
			return 'Windows Phone'
		}

		if (/android/i.test(userAgent)) {
			return 'Android'
		}

		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			return 'iOS'
		}

		return 'unknown'
	}

	function safari () {
		return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
	}

	function newIOS () {
		const userAgent = navigator.userAgent || navigator.vendor || window.opera
		if (userAgent.indexOf('13_4') !== -1) {
			return true
		} else {
			return false
		}
	}

	function browser () {
		if (mobileOS() === 'iOS') {
			if (navigator.userAgent.match('CriOS') !== null) {
				return 'Chrome'
			} else {
				return 'Safari'
			}
		} else {
			if (navigator.userAgent.match('Safari')) {
				return 'Chrome'
			} else {
				return 'Other'
			}
		}
	}
}

export default Identify
