import React from 'react'
import Footer from '../Footer'
import styles from './HomePlaceholder.module.css'

export default function HomePlaceholder () {
	return (
		<div className={styles.root}>
			<div>&nbsp;</div>
			<img
				style={{
					width: '60vw',
					margin: '100px auto 0',
					maxWidth: 700
				}}
				src={require('../img/2021/landing-logo-alt.png').default}
				alt="11th annual Lovie Awards ❤"
			/>

			<div className={styles.text} />

			<div style={{ marginBottom: 100, fontSize: '1.1em' }}>
                Mark Your Diaries: The 11th Annual Lovie Awards kicks-off right here on Wednesday, 17th November at 14:00 GMT. <br /><br />
                Hosted by Deborah Frances-White of The Guilty Feminist!
			</div>

			<Footer full={false} />
		</div>
	)
}
